function Footer() {
    return (
        <footer class="pt-1 pb-3 bg-bottom">
            <div class="container mt-5">
                <div class="row mb-5">
                    <div class="col-1"></div>
                    <div class="col-11 col-md-5 col-lg-7 cold-flex flex-column text-left mb-4">
                        <h5 class="heading-black mb-1">General Inquiries</h5>
                        <a href="mailto:contact@marsauto.com">contact@marsauto.com</a>
                        <div class="mb-3"></div>
                        <h5 class="heading-black mb-1">Job Opportunities</h5>
                        <a href="mailto:jobs@marsauto.com">jobs@marsauto.com</a>
                        <h5 class="heading-black mb-1">--</h5>
                        <h5><a class="heading-black mb-1" href="https://marsauto-website.s3.ap-northeast-2.amazonaws.com/2024_sandbox.pdf">규제특례 이용자 고지사항</a></h5>
                        <h5><a class="heading-black mb-1" href="https://marsauto-website.s3.ap-northeast-2.amazonaws.com/%EC%9C%84%EC%B9%98%EC%A0%95%EB%B3%B4%EC%82%AC%EC%97%85+%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80+2024.08.pdf">개인위치정보사업자 이용약관</a></h5>
                        <h5><a class="heading-black mb-1" href="https://marsauto-website.s3.ap-northeast-2.amazonaws.com/%EC%9C%84%EC%B9%98%EC%A0%95%EB%B3%B4%EC%82%AC%EC%97%85+%EA%B0%9C%EC%9D%B8%EC%9C%84%EC%B9%98%EC%A0%95%EB%B3%B4+%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8+2024.08.pdf">개인위치정보 처리방침</a></h5>
                    </div>
                    <div class="d-md-none col-1"></div>
                    <div class="col-11 col-md-3 col-lg-2 d-flex flex-row mb-4">
                        <div>
                            <div class="pb-1">
                                <a target="_blank" href="https://www.youtube.com/channel/UCVNqxe4aD3bH0WyWkA3o1RA"><i
                                    class="fa-brands fa-youtube"></i></a>
                            </div>
                            <div class="pb-1">
                                <a target="_blank" href="https://www.linkedin.com/company/marsauto/"><i
                                    class="fa-brands fa-linkedin"></i></a>
                            </div>
                            <div class="pb-1">
                                <a target="_blank" href="mailto:contact@marsauto.com"><i
                                    class="fa-solid fa-envelope"></i></a>
                            </div>
                        </div>
                        <div class="col-6 d-flex flex-column text-left ">
                            <a class="pb-1" href="https://www.youtube.com/channel/UCVNqxe4aD3bH0WyWkA3o1RA">Youtube</a>
                            <a class="pb-1" href="https://www.linkedin.com/company/marsauto/">LinkedIn</a>
                            <a class="pb-1" href="mailto:contact@marsauto.com">Mail</a>
                        </div>
                    </div>
                    <div class="d-md-none col-1"></div>
                    <div class="col-11 col-md-3 col-lg-2 d-flex flex-column text-left mb-4">
                        <a class="pb-1" href="/">Main</a>
                        <a class="pb-1" href="/technology">Technology</a>
                        <a class="pb-1" href="/careers">Careers</a>
                        <a class="pb-1" href="/press">Press</a>
                        <div class="d-flex flex-row">
                            <a class="pb-1" href="https://blog.marsauto.com/">Blog <i class="fa-xs fa-solid fa-up-right-from-square"></i></a>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-12 text-center small-xl">
                        &copy; 2024 Mars Auto - All Rights Reserved<br />
                    </div>
                </div>
            </div>
        </footer >
    );
}

export default Footer;
