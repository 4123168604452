import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import LanguageSelector from './LanguageSelector'; // Import the LanguageSelector
import logo from './static/logo_white.webp';
import { MenuItem, MenuLinkItem } from './menuItem';
import { useTranslation } from 'react-i18next';

function Menu() {
    const { t, i18n } = useTranslation();

    // Define your links for each language
    const links = {
        en: "https://www.notion.so/marsauto/MARS-AUTO-IS-WHERE-THE-BEST-BELONG-2ddaf03ab3a14d258fa32a5507e5d337?pvs=4",
        ko: "https://www.notion.so/marsauto/MARS-AUTO-IS-WHERE-THE-BEST-BELONG-2ddaf03ab3a14d258fa32a5507e5d337?pvs=4"
    };

    // Get the current language and the corresponding link
    const currentLanguage = i18n.language;
    const cultureLink = links[currentLanguage] || links.en; // Fallback to English if no match
    const showCulture = currentLanguage === "ko";

    return (
        <Navbar variant="dark" bg="black" expand="md" className="fixed-top">
            <a class="navbar-brand heading-black ml-2" href="/">
                <img alt="marsauto logo" src={logo} class="image logo"></img>
            </a>
            <Navbar.Toggle aria-controls="navbarCollapse" />
            <Navbar.Collapse id="navbarCollapse">
                <Nav className="ml-auto">
                    <MenuItem title={t('menu_title1')} href="/technology" />
                    {/* <MenuItem title="Business" href="/business" /> */}
                    <MenuItem title={t('menu_title2')} href="/careers" />
                    <MenuItem title={t('menu_title3')} href="/press" />
                    {showCulture ?
                        <MenuLinkItem title={t('menu_title6')} href={cultureLink} target="_blank" /> : <script/>}
                    <MenuLinkItem title={t('menu_title4')} href="https://preciouscargo.marsauto.com/" target="_blank" />
                    <MenuLinkItem title={t('menu_title5')} href="https://blog.marsauto.com/" target="_blank" />
                    <LanguageSelector />
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    );
}

export default Menu;
